@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@vivavet-primary: #49A6DB;
@vivavet-primary-faded-85: hsla(@vivavet-primary, 0.85);
@vivavet-primary-faded-80: hsla(@vivavet-primary, 0.8);
@vivavet-black: #252525;
@vivavet-black-faded: hsla(@vivavet-black, 0.8);

@primary-color: @vivavet-primary;

@heading-color: @vivavet-primary;
@text-color: @vivavet-black-faded;
@prefix-color: hsla(@text-color, 0.6);
@label-color: @text-color;

@border-radius-base: 8px;
@font-size-base: 16px;
@input-height-base: 40px;

@includeLessVars: {
  display: none !important;
  @vars: primary-color,primary-1,primary-2,background-color-light,text-color,disabled-color,error-color,red-2,border-color-base,border-radius-base;
  .-(@i: length(@vars)) when (@i > 0)
  {
    @name: extract(@vars, @i);
    --@{name}: @@name !important;
    .-((@i - 1));
  }
  .-;
}

@import '~antd/lib/style/core/index.less';
@import '~antd/lib/style/components.less';

#__LESSVARS__ {
  @includeLessVars();
}

#admin #sidebar .ant-menu-item {
  &:after { display: none !important }
  border-radius: 10px;
  height: 45px;
  line-height: 45px;
}

#admin {
  // font-weight: 500;
  > .ant-layout,
  > .ant-layout-content {
    margin-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
  &[data-hide-header="false"] > #header {
    height: calc(80px + env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
  }
  &[data-hide-sidebar="false"] > #sidebar {
    padding-top: calc(20px + env(safe-area-inset-top));
  }
  .page-header {
    margin-bottom: 40px !important;
    .page-header-left {
      width: 70%;
      .page-header-title {
        font-size: 34px !important;
      }
    }

  }
  .ant-btn-primary {
    text-shadow: none !important;
    box-shadow: none !important;
    margin-right: 10px !important;
  }
  .ant-table-body {
    box-shadow: 0 0 0 1px inset #d9d9d9;
    border-radius: 8px;
  }
  .ant-select-arrow .ant-select-arrow-icon svg {
    transform: none !important;
  }
  .ant-input {
    line-height: 1.5 !important;
    // font-weight: 500;
  }
  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    background: none !important;
  }
  .ant-divider-horizontal.ant-divider-with-text-left {
    .ant-divider-inner-text {
      padding: 0
    }
    &::before {
      width: 0% !important;
    }
  }
  // .section_cards_repeater .ant-form.ant-form-vertical > .ant-row > div:first-child,
  // .section_slider_repeater .ant-form.ant-form-vertical > .ant-row > div:nth-child(-n+3) {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   .ant-form-item-label {
  //     text-align: center !important;
  //   }
  //   .ant-upload.ant-upload-select-picture-card {
  //     margin: 0;
  //   }
  //   .ant-upload-list .ant-upload-list-picture-card-container {
  //     margin: 0;
  //   }
  // }
  // .section_slider_repeater_actions .ant-form.ant-form-vertical > .ant-row > div:nth-child(-n+3) {
  //   display: block;
  // }
  // &[data-is-mobile="false"] {
  //   .section_cards_repeater {
  //     .ant-form.ant-form-vertical > .ant-row > div:first-child {
  //       height: 250px;
  //     }
  //   }
  // }
  .ant-input-affix-wrapper .ant-input-prefix > span,
  .ant-form-item-children > span:nth-child(2),
  .ant-col.ant-form-item-label > label {
    color: @prefix-color;
  }
  .ant-form-item-required::before {
    display: none;
  }


  .ant-col-1 {
    width: auto !important;
    @media (max-width: 600px) {
      width: 100% !important;
    }
  }
}
.ant-popover-message > .anticon &[data-icon="exclamation-circle"] {
  color: #ff4d4f;
}

.potvrda-modal {
  .ant-modal {
    width: 720px !important;
  }
}
